import React from 'react'
import Modal from '@components/Modal'
import Loader, { LoaderContainer } from '@components/Loader'
import { useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import FormSave from '../FormSave'
import ListNotas from '../ListNotas'
import { setOpenModalAgendamento } from '../../reducers/agendamentoConsultaReducer'

const ModalAgendamento: React.FC = () => {

    const { currentAgendamento, agendamento } = useAppSelector(s => s.agendamento)
    const { openModalAgendamento } = useAppSelector(s => s.agendamentoConsulta)
    const { requestGetAgendamento } = useAppSelector(s => s.requestAgendamento)

    const CR = conditionaRender(requestGetAgendamento, agendamento as any)

    return(

        <Modal
            title = {`Agendamento - ${currentAgendamento?.id}`}
            width = "80%"
            open = {openModalAgendamento}
            setOpen = {setOpenModalAgendamento}
        >
            {CR.LOADING && (
                <LoaderContainer>
                    <Loader size = {64} border = {8} />
                </LoaderContainer>
            )}
            {(CR.DATA && !!agendamento) && (
                <>
                    <FormSave />
                    <ListNotas />
                </>
            )}
        </Modal>

    )

}

export default ModalAgendamento