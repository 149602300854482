import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const Column: React.FC <{width: string, text: string | number}> = ({ width, text }) => {

    const styles = StyleSheet.create({
        column: {
            width,
            paddingHorizontal: 4,
        },
        text: {
            fontSize: 8,
            fontWeight: 'bold',
        }
    })

    return(

        <View style = {styles.column}>
            <Text style = {styles.text}>{text}</Text>
        </View>

    )

}

const HeaderRow: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingVertical: 4,
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
        },
    })

    return(

        <View style = {styles.row}>
            <Column width = "30%" text = "TRANSPORTADORA" />
            <Column width = "10%" text = "OPERAÇÃO" />
            <Column width = "20%" text = "MOTORISTA" />
            <Column width = "10%" text = "PLACA" />
            <Column width = "10%" text = "HORÁRIO" />
            <Column width = "8%" text = "PESO" />
            <Column width = "12%" text = "NF-s" />
        </View>

    )

}

export default HeaderRow