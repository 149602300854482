import { RoutePageType } from "@routes/interfaces"
import AgendamentoCadastro from "./pages/AgendamentoCadastro"
import AgendamentoCadastroFiscal from "./pages/AgendamentoCadastroFiscal"
import AgendamentoCadastroHorario from "./pages/AgendamentoCadastroHorario"
import AgendamentoConsulta from "./pages/AgendamentoConsulta"
import AgendamentoConsultaBloqueados from "./pages/AgendamentoConsultaBloqueados"
import AgendamentoEditar from "./pages/AgendamentoEditar"
import AgendamentoLista from "./pages/AgendamentoLista"
import AgendamentoImpressao from "./pages/AgendamentoImpressao"

const agendamentoPages: RoutePageType[] = [
    {key: 'agendamentoConsulta', element: AgendamentoConsulta},
    {key: 'agendamentoCadastro', element: AgendamentoCadastro},
    {key: 'agendamentoCadastroFiscal', element: AgendamentoCadastroFiscal},
    {key: 'agendamentoCadastroHorario', element: AgendamentoCadastroHorario},
    {key: 'agendamentoLista', element: AgendamentoLista},
    {key: 'agendamentoConsultaBloqueados', element: AgendamentoConsultaBloqueados},
    {key: 'agendamentoEditar', element: AgendamentoEditar},
    {key: 'agendamentoImpressao', element: AgendamentoImpressao},
]

export default agendamentoPages