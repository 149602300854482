import ENV from "@env/index"

const SHOW_INFO = {
    LOG: false,
    DATA: false,
    ERROR: false,
    WARN: false,
}

const SHOW_REQUEST_INFO = {
    REQUEST: false,
    HEADER: false,
    BODY: false,
    RESPONSE: false,
    ERROR: false,
}

const ROUTE_INDEX = 'agendamento'

const APP_VERSION = {
    NAME: '1.16.9',
    RELEASE_DATE: '20/01/2025',
    DEPLOY_ENV: ENV.APP_DEPLOY_ENVIRONMENT,
    FLAG_QAS: ENV.FLAG_QAS === 'true',
}

const FLAG_TESTE = 'N'

const config = {
    SHOW_INFO, SHOW_REQUEST_INFO, APP_VERSION, ROUTE_INDEX, FLAG_TESTE
}

export default config
export { SHOW_INFO, SHOW_REQUEST_INFO, APP_VERSION, ROUTE_INDEX, FLAG_TESTE }