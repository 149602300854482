import React from 'react'
import ENV from "@env/index"
import { APP_VERSION, FLAG_TESTE } from "@config/index"
import { Cliente } from "@interfaces/Cliente"
import { Motorista } from "@interfaces/Motorista"
import { NotaFiscal } from "@interfaces/NotaFiscal"
import { Agendamento } from "@interfaces/Agendamento"
import { TipoVeiculo } from "@interfaces/TipoVeiculo"
import { NotaFiscalCnpj } from '@interfaces/NotaFiscalCnpj'
import { AgendamentoAtual } from "@interfaces/AgendamentoAtual"
import { AgendamentoLista } from "@interfaces/AgendamentoLista"
import { SetFieldValueType } from "@interfaces/SetFieldValue"
import createAlert from "@modules/app/scripts/alert/createAlert"
import { setAlert } from "@modules/app/reducers/alertReducer"
import { UserData } from "@modules/auth/interfaces/UserData"
import getNroDocumento from "@modules/auth/scripts/getNroDocumento"
import { getBloqueios } from '@modules/armazem/controllers'
import { DispatchType } from "@redux/types"
import request from "@utils/request"
import { jsonToParams } from "@utils/jsonToParams"
import { ResponsePattern } from "@utils/response/types"
import { handleResponse, initRequest } from "@utils/response"
import { PainelEditValues } from '../pages/AgendamentoEditar/interfaces/PainelEditValues'
import { GetMotoristasValues } from "../interfaces/GetMotoristas"
import { GetNotaFiscalSearch } from "../interfaces/GetNotaFiscal"
import { setOpenModalDesbloqueio } from "../pages/AgendamentoConsultaBloqueados/reducers/agendamentoConsultaBloqueadosReducer"
import { setOpenModalAgendamento } from "../pages/AgendamentoLista/reducers/agendamentoListaReducer"
import { formValues as formAddValues } from "../components/NotaFiscal/FormAdd/config"
import { formValues as formFilterValues } from "../pages/AgendamentoConsulta/components/FormFilter/config"
import { formValues as formCreateValues } from "../pages/AgendamentoCadastro/components/FormCreate/config"
import { setModalSaveValues, setOpenModalSave } from "../pages/AgendamentoCadastroHorario/reducers/agendamentoCadastroHorarioReducer"
import { setAgendamento, setAgendamentoFilterValues, setAgendamentoLista, setAgendamentos, setAgendamentosBloqueados, setAgendamentosLista, setClientes, setMotoristas, setNotasFiscais, setNotasFiscaisCnpj, setTiposVeiculos } from "../reducers/agendamentoReducer"
import { requestCreateAgendamentoReducers, requestEditAgendamentoReducers, requestGerarDanfeReducers, requestGetAgendamentoListaReducers, requestGetAgendamentoReducers, requestGetAgendamentosBloqueadosReducers, requestGetAgendamentosListaReducers, requestGetAgendamentosReducers, requestGetClientesReducers, requestGetMotoristasReducers, requestGetNotasFiscaisCnpjReducers, requestGetNotasFiscaisReducers, requestGetTiposVeiculoReducers, requestRemoveAgendamentoReducers, requestRemoveNotaFiscalReducers, requestSaveAgendamentoHorarioReducers, requestSaveAgendamentoReducers, requestSaveNotaFiscalReducers, requestSetAgendamentoDesbloqueioReducers, requestVincularAgendamentoNotaFiscalReducers } from "../reducers/requestAgendamentoReducer"

export async function getAgendamentosLista(dispatch: DispatchType, userData: UserData){
    dispatch(requestGetAgendamentosListaReducers.reset())
    dispatch(requestGetAgendamentosListaReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoListar`
    const params = `?flagTeste=${FLAG_TESTE}&${jsonToParams(getNroDocumento(userData))}`
    const response = await request.get<ResponsePattern<AgendamentoLista[]>>({ endpoint, params })

    handleResponse('getAgendamentosLista', dispatch, response, requestGetAgendamentosListaReducers)
    .then(agendamentos => {
        dispatch(setAgendamentosLista(agendamentos))
    })
}

export async function filterAgendamentosLista(dispatch: DispatchType, codigo: string){
    dispatch(requestGetAgendamentosListaReducers.reset())
    dispatch(requestGetAgendamentosListaReducers.loading())

    const endpoint = `${ENV.APP_ENDPOINT}/agendamento/pesquisar`
    const params = `/${codigo}`
    const response = await request.get<ResponsePattern<AgendamentoLista[]>>({ endpoint, params })

    handleResponse('filterAgendamentosLista', dispatch, response, requestGetAgendamentosListaReducers)
    .then(agendamentos => {
        dispatch(setAgendamentosLista(agendamentos))
    })
}

export async function getAgendamentos(dispatch: DispatchType, values: typeof formFilterValues, userData: UserData, guidArmazem?: string){
    dispatch(requestGetAgendamentosReducers.reset())
    dispatch(requestGetAgendamentosReducers.loading())

    dispatch(setAgendamentoFilterValues(values))

    if(!!guidArmazem){
        await getBloqueios(dispatch, guidArmazem, values.data, userData)
    }

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoListarHorarioNew`
    const params = `?${jsonToParams({...values, ...getNroDocumento(userData)})}&flagEmDigitacao=false&flagTeste=${FLAG_TESTE}`
    const response = await request.get<ResponsePattern<Agendamento[]>>({ endpoint, params })

    handleResponse('getAgendamentos', dispatch, response, requestGetAgendamentosReducers)
    .then(async agendamentos => {
        dispatch(setModalSaveValues(null))
        dispatch(setAgendamentos(agendamentos))
    })
}

export async function getAgendamentosBloqueados(dispatch: DispatchType, values: typeof formFilterValues, userData: UserData){
    dispatch(requestGetAgendamentosBloqueadosReducers.reset())
    dispatch(requestGetAgendamentosBloqueadosReducers.loading())

    dispatch(setAgendamentoFilterValues(values))

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoListarHorarioNew`
    const params = `?${jsonToParams({...values, ...getNroDocumento(userData)})}&flagBloqueio=S&flagEmDigitacao=false&flagTeste=${FLAG_TESTE}`
    const response = await request.get<ResponsePattern<Agendamento[]>>({ endpoint, params })

    handleResponse('getAgendamentosBloqueados', dispatch, response, requestGetAgendamentosBloqueadosReducers)
    .then(agendamentos => {
        dispatch(setAgendamentosBloqueados(agendamentos))
    })
}

export async function getAgendamento(dispatch: DispatchType, idAgendamento: number, userData: UserData, signal?: AbortSignal){
    dispatch(setAgendamento(null))
    
    dispatch(requestGetAgendamentoReducers.reset())
    dispatch(requestGetAgendamentoReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/Agendamento`
    const params = `?id=${idAgendamento}&${jsonToParams(getNroDocumento(userData))}`
    const response = await request.get<ResponsePattern<AgendamentoAtual[]>>({ endpoint, params })

    handleResponse('getAgendamento', dispatch, response, requestGetAgendamentoReducers)
    .then(data => {
        dispatch(setAgendamento(data[0]))
    })
}

export async function saveAgendamento(dispatch: DispatchType, values: any, idAgendamento: number, reload: () => void, userData: UserData){
    dispatch(requestSaveAgendamentoReducers.reset())
    dispatch(requestSaveAgendamentoReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoSalvarHistorico`
    const body = {
        ...values,
        ...getNroDocumento(userData),
        id: idAgendamento,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveAgendamento', dispatch, response, requestSaveAgendamentoReducers)
    .then(() => {
        dispatch(setOpenModalAgendamento(false))
        reload()
    })
}

export async function getTiposVeiculo(dispatch: DispatchType, userData: UserData){
    dispatch(requestGetTiposVeiculoReducers.reset())
    dispatch(requestGetTiposVeiculoReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamento/TipoVeiculo`
    const params = `?${jsonToParams(getNroDocumento(userData))}`
    const response = await request.get<ResponsePattern<TipoVeiculo[]>>({ endpoint, params })

    handleResponse('getTiposVeiculo', dispatch, response, requestGetTiposVeiculoReducers)
    .then(data => dispatch(setTiposVeiculos(data)))
}

export async function getClientes(dispatch: DispatchType, userData: UserData){
    dispatch(requestGetClientesReducers.reset())
    dispatch(requestGetClientesReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamento/Cliente`
    const params = `?${jsonToParams(getNroDocumento(userData))}`
    const response = await request.get<ResponsePattern<Cliente[]>>({ endpoint, params })

    handleResponse('getClientes', dispatch, response, requestGetClientesReducers)
    .then(data => dispatch(setClientes(data)))
}

export async function getNotasFiscais(dispatch: DispatchType, search: GetNotaFiscalSearch, userData: UserData){
    dispatch(requestGetNotasFiscaisReducers.reset())
    dispatch(requestGetNotasFiscaisReducers.loading())

    const { guid, idAgendamento } = search

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoNotaFiscalListar`
    const params = `?${jsonToParams(getNroDocumento(userData))}&${!!guid ? jsonToParams({guid}) : !!idAgendamento ? jsonToParams({idAgendamento}) : undefined}`
    const response = await request.get<ResponsePattern<NotaFiscal[]>>({ endpoint, params })

    handleResponse('getNotasFiscais', dispatch, response, requestGetNotasFiscaisReducers)
    .then(data => dispatch(setNotasFiscais(data)))
}

export async function getTokenCreateAgendamento(tipo: string, userData: UserData){
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoEmDigitacaoSalvar`
    const params = `?${jsonToParams({tipo, ...getNroDocumento(userData)})}&flagTeste=${FLAG_TESTE}`
    const response = await request.get<ResponsePattern<string[]>>({ endpoint, params })

    if(!!response && !response.flagErro) return response.listaResultados[0]
    return null
}

export async function createAgendamento(dispatch: DispatchType, values: typeof formCreateValues, redirect: React.Dispatch<React.SetStateAction<string>>, userData: UserData, idMotorista: number | null){
    dispatch(requestCreateAgendamentoReducers.reset())
    dispatch(requestCreateAgendamentoReducers.loading())
    
    const guid = await getTokenCreateAgendamento(values.tipoAgendamento, userData)

    if(!!guid){
        const { obs, armazem, nomeMotorista, rgMotorista, cpfMotorista, cnhMotorista, placaVeiculo, cliente, peso, placaReboque1, placaReboque2, tipoVeiculo } = values

        const options = {messages: {errorMessage: {}}}
        const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoSalvar`
        const body = {
            ...getNroDocumento(userData),
            idUsuario: userData.id,
            flagTeste: FLAG_TESTE,
            idArmazem: armazem,
            idMotorista,
            nomeMotorista,
            rgMotorista,
            cpfMotorista,
            cnhMotorista,
            idVeiculo: placaVeiculo.replaceAll('-', ''),
            nmCliente: cliente,
            placa: placaVeiculo.replaceAll('-', ''),
            placa2: placaReboque1.replaceAll('-', ''),
            placa3: placaReboque2.replaceAll('-', ''),
            tipoVeiculo,
            versao: APP_VERSION.NAME,
            peso,
            guid,
            obs,
        }
        const response = await request.post<ResponsePattern<any>>({ endpoint, body })

        handleResponse('createAgendamento', dispatch, response, requestCreateAgendamentoReducers, options)
        .then(data => {
            redirect(guid)
        })
    }
}

export async function getMotoristas(dispatch: DispatchType, values: GetMotoristasValues, userData: UserData, setFieldValue: SetFieldValueType){
    dispatch(requestGetMotoristasReducers.reset())
    dispatch(requestGetMotoristasReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Viagem/Motorista`
    const params = `?${jsonToParams({ ...values, ...getNroDocumento(userData) })}`
    const response = await request.get<ResponsePattern<Motorista[]>>({ endpoint, params })

    handleResponse('getMotoristas', dispatch, response, requestGetMotoristasReducers)
    .then(data => {
        dispatch(setMotoristas(data))

        const formKeys = ['cpfMotorista', 'nomeMotorista', 'rgMotorista', 'cnhMotorista']

        if(data.length > 0){
            const { documento, nome, rg, nroCNH } = data[0]

            formKeys.forEach((key, index) => setFieldValue(key, [documento, nome, rg ?? '', nroCNH ?? ''][index]))
        }else{
            formKeys.forEach(key => setFieldValue(key, ''))

            if(values.nome.length > 0 || values.documento.length > 0){
                dispatch(setAlert(createAlert('error', 'Nenhum motorista encontrado!')))
            }
        }
    })
}

export async function saveNotaFiscal(dispatch: DispatchType, values: typeof formAddValues, guid: string, userData: UserData){
    dispatch(requestSaveNotaFiscalReducers.reset())
    dispatch(requestSaveNotaFiscalReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoNotaFiscalSalvar`
    const body = {
        ...values,
        ...getNroDocumento(userData),
        guid,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveNotaFiscal', dispatch, response, requestSaveNotaFiscalReducers, options)
    .then(() => getNotasFiscais(dispatch, { guid }, userData))
}

export async function removeNotaFiscal(dispatch: DispatchType, guid: string, id: number, userData: UserData){
    dispatch(requestRemoveNotaFiscalReducers.reset())
    dispatch(requestRemoveNotaFiscalReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoNotaFiscalExcluir`
    const body = { id }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('removeNotaFiscal', dispatch, response, requestRemoveNotaFiscalReducers, options)
    .then(() => getNotasFiscais(dispatch, { guid }, userData))
}

export async function saveAgendamentoHorario(dispatch: DispatchType, guid: string, data: string, hora: string, nrDoca: number, flagBloqueio: boolean, setRedirect: React.Dispatch<React.SetStateAction<boolean>>, userData: UserData){
    dispatch(requestSaveAgendamentoHorarioReducers.reset())
    dispatch(requestSaveAgendamentoHorarioReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoSalvarHorario`
    const body = { guid, data, hora, nrDoca, flagBloqueio: flagBloqueio ? 'S' : 'N', flagTeste: FLAG_TESTE, ...getNroDocumento(userData), idUsuarioCadastro: userData.id }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('saveAgendamentoHorario', dispatch, response, requestSaveAgendamentoHorarioReducers, options)
    .then(() => {
        dispatch(setOpenModalSave(false))
        setRedirect(true)
    })
}

export async function removeAgendamento(dispatch: DispatchType, id: number, userData: UserData){
    dispatch(requestRemoveAgendamentoReducers.reset())
    dispatch(requestRemoveAgendamentoReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoExcluir`
    const body = { id, ...getNroDocumento(userData) }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('removeAgendamento', dispatch, response, requestRemoveAgendamentoReducers, options)
    .then(() => {
        dispatch(setOpenModalAgendamento(false))
        getAgendamentosLista(dispatch, userData)
    })
}

export async function editAgendamento(
    dispatch: DispatchType,
    formValues: any,
    painel: PainelEditValues,
    data: string,
    userData: UserData,
    idMotorista: number | null
){
    dispatch(requestEditAgendamentoReducers.reset())
    dispatch(requestEditAgendamentoReducers.loading())

    const { id, guid, obs, armazem, nomeMotorista, rgMotorista, cpfMotorista, cnhMotorista, placaVeiculo, cliente, peso, placaReboque1, placaReboque2, tipoVeiculo } = formValues

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoEdicao`
    const body = {
        ...getNroDocumento(userData),
        id,
        guid,
        data,
        hora: painel.hour,
        doca: painel.dock,
        idUsuario: userData.id,
        flagTeste: FLAG_TESTE,
        idArmazem: armazem,
        idMotorista,
        nomeMotorista,
        rgMotorista,
        cpfMotorista,
        cnhMotorista,
        idVeiculo: placaVeiculo.replaceAll('-', ''),
        nmCliente: cliente,
        placaVeiculo: placaVeiculo.replaceAll('-', ''),
        placa2: placaReboque1.replaceAll('-', ''),
        placa3: placaReboque2.replaceAll('-', ''),
        tipoVeiculo,
        versao: APP_VERSION.NAME,
        peso,
        obs,
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('editAgendamento', dispatch, response, requestEditAgendamentoReducers, options)
    .then(() => {
        getAgendamentosLista(dispatch, userData)
        getAgendamentoLista(dispatch, Number(formValues.id))
    })
}

export async function setAgendamentoDesbloqueio(dispatch: DispatchType, guid: string, values: typeof formFilterValues, userData: UserData){
    dispatch(requestSetAgendamentoDesbloqueioReducers.reset())
    dispatch(requestSetAgendamentoDesbloqueioReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoDesbloquear`
    const params = `?${jsonToParams({guid, ...getNroDocumento(userData)})}`
    const response = await request.get<ResponsePattern<any>>({ endpoint, params })

    handleResponse('setAgendamentoDesbloqueio', dispatch, response, requestSetAgendamentoDesbloqueioReducers, options)
    .then(() => {
        dispatch(setOpenModalDesbloqueio(false))
        getAgendamentosBloqueados(dispatch, values, userData)
    })
}

export async function getAgendamentoNotasFiscaisCnpj(dispatch: DispatchType, cnpj: string){
    initRequest(dispatch, requestGetNotasFiscaisCnpjReducers)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `http://3.228.242.23:9010/nf-por-cnpj`
    const body = {
        cnpj,
    }
    const response = await request.post<ResponsePattern<NotaFiscalCnpj[]>>({ endpoint, body })

    handleResponse('getAgendamentoNotasFiscaisCnpj', dispatch, response, requestGetNotasFiscaisCnpjReducers, options)
    .then(data => {
        dispatch(setNotasFiscaisCnpj(data))
    })
}

export async function vincularAgendamentoNotaFiscal(dispatch: DispatchType, userData: UserData, values: any, reload: () => void){
    initRequest(dispatch, requestVincularAgendamentoNotaFiscalReducers)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `http://3.228.242.23:9010/vincular-nota-agendamento`
    const body = {
        ...getNroDocumento(userData),
        ...values
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('vincularAgendamentoNotaFiscal', dispatch, response, requestVincularAgendamentoNotaFiscalReducers, options)
    .then(() => {
        reload()
    })
}

export async function gerarDanfe(dispatch: DispatchType, xml: string){
    initRequest(dispatch, requestGerarDanfeReducers)

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `http://3.228.242.23:9010/gerar-danfe`
    const body = {
        xml
    }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('gerarDanfe', dispatch, response, requestGerarDanfeReducers, options)
    .then(data => {
        let byteArray = new Uint8Array(data.length)

        for (var i = 0; i < data.length; i++) {
            byteArray[i] = data.charCodeAt(i) & 0xff
        }

        let blob = new Blob([byteArray], { type: 'application/pdf' })
        let blobUrl = URL.createObjectURL(blob)

        window.open(blobUrl, '_blank')
    })
}

export async function getAgendamentoLista(dispatch: DispatchType, id: number){
    initRequest(dispatch, requestGetAgendamentoListaReducers)

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/AgendamentoCarregar`
    const params = `/?${jsonToParams({ id })}`
    const response = await request.get<ResponsePattern<AgendamentoLista[]>>({ endpoint, params })

    handleResponse('getAgendamentoLista', dispatch, response, requestGetAgendamentoListaReducers)
    .then(data => {
        dispatch(setAgendamentoLista(data[0]))
    })
}