import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { mdiDelete, mdiEye } from '@mdi/js'
import List, { ListColumn, ListLoader, ListMessage, ListOption, ListRow, ListSection } from '@components/List'
import { gerarDanfe, getNotasFiscais, removeNotaFiscal } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'
import { ListNotaFiscalProps } from './types'

const ListNotaFiscal: React.FC <ListNotaFiscalProps> = ({ options = true }) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { notasFiscais } = useAppSelector(s => s.agendamento)
    const { requestGetNotasFiscais } = useAppSelector(s => s.requestAgendamento)
    const { guid } = useParams()

    const CR = conditionaRender(requestGetNotasFiscais, notasFiscais, true)

    const listOptions = {
        grid: `1fr 1fr${options ? ' 52px' : ''}`,
        header: ['N° Nota', 'N° Série'],
    }

    if(options){
        listOptions.header.push('Opções')
    }

    useEffect(() => {
        if(!!guid){
            getNotasFiscais(dispatch, { guid }, userData!)
        }
    }, [dispatch, guid, userData])

    return(

        <>
            <List name = "nf">
                <ListSection type = "header">
                    <ListRow grid = {listOptions.grid}>
                        {listOptions.header.map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection>
                    <>
                        {CR.LOADING && <ListLoader />}
                        {CR.NODATA && <ListMessage text = "Nenhuma nota fiscal encontrada" />}
                        {CR.DATA && notasFiscais!.map((item, index) => (
                            <ListRow key = {index} grid = {listOptions.grid}>
                                <ListColumn>{item.nroNota}</ListColumn>
                                <ListColumn>{item.nroSerie}</ListColumn>
                                {options && (
                                    <ListColumn>
                                        <ListOption
                                            icon = {mdiDelete}
                                            status = "error"
                                            onClick = {() => removeNotaFiscal(dispatch, guid!, item.id, userData!)}
                                        />
                                        <ListOption
                                            icon = {mdiEye}
                                            onClick = {() => {
                                                if(!item.caminhoArquivo && !!item.xml){
                                                    gerarDanfe(dispatch, item.xml)
                                                }else{
                                                    window.open(item.caminhoArquivo)
                                                }
                                            }}
                                        />
                                    </ListColumn>
                                )}
                            </ListRow>
                        ))}
                    </>
                </ListSection>
            </List>
        </>

    )

}

export default ListNotaFiscal