import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { mdiFilePdfBox } from '@mdi/js'
import Button from '@components/Button'
import Section from '@components/Page/Section'
import SectionBox from '@components/Page/Section/box'
import Loader, { LoaderContainer } from '@components/Loader'
import ListNotaFiscal from '@modules/agendamento/components/NotaFiscal/List'
import { getAgendamentoLista } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import conditionaRender from '@utils/conditionalRender'

const AgendamentoImpressao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { agendamentoLista } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentoLista } = useAppSelector(s => s.requestAgendamento)

    const contentRef = useRef<HTMLDivElement>(null)

    const { idAgendamento } = useParams()

    const CR_AGENDAMENTO = conditionaRender(requestGetAgendamentoLista as any, agendamentoLista)

    const reactToPrintFn = useReactToPrint({ contentRef })

    useEffect(() => {
        if(!!idAgendamento){
            getAgendamentoLista(dispatch, Number(idAgendamento))
        }
    }, [dispatch, idAgendamento])

    return(

        <div ref = {contentRef}>
            <Section>
                <SectionBox
                    title = {`Agendamento - ${idAgendamento}`}
                    right = {
                        <Button
                            icon = {mdiFilePdfBox}
                            label = "Imprimir"
                            onClick = {reactToPrintFn}
                        />
                    }
                >
                    {CR_AGENDAMENTO.LOADING && (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    )}
                </SectionBox>
            </Section>

            <Section>
                <SectionBox title = "Notas Fiscais" padding = {false}>
                    <ListNotaFiscal options = {false} />
                </SectionBox>
            </Section>
        </div>

    )

}

export default AgendamentoImpressao