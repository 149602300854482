import { UserData } from '@modules/auth/interfaces/UserData'
import * as yup from 'yup'

export const formValues = {
    tipoAgendamento: '',
    cliente: '',
    peso: '',
    armazem: '',

    pesquisaNomeMotorista: '',
    pesquisaDocMotorista: '',
    selectMotorista: '',
    
    cpfMotorista: '',
    nomeMotorista: '',
    rgMotorista: '',
    cnhMotorista: '',
    placaVeiculo: '',
    tipoVeiculo: '',
    placaReboque1: '',
    placaReboque2: '',
    obs: ''
}

const defaultSchema = {
    tipoAgendamento: yup.string().required('Campo obrigatório'),
    cliente: yup.string().required('Campo obrigatório'),
    armazem: yup.string().required('Campo obrigatório'),
    cpfMotorista: yup.string().required('Campo obrigatório'),
    nomeMotorista: yup.string().required('Campo obrigatório'),
    rgMotorista: yup.string().required('Campo obrigatório'),
    cnhMotorista: yup.string().required('Campo obrigatório'),
    placaVeiculo: yup.string().required('Campo obrigatório'),
    tipoVeiculo: yup.string().required('Campo obrigatório'),
}

const pesoSchema = {
    peso: yup.string().required('Campo obrigatório')
}

export const formSchema = (user: UserData) => {
    return yup.object().shape({
        ...defaultSchema,
        ...([4, 10, 13, 6, 8, 9].includes(user.grupoAcesso.id)) ? pesoSchema : {}
    })
}