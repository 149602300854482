import React, { useEffect } from 'react'
import { mdiClose } from '@mdi/js'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import ButtonGroup from '@components/Button/Group'
import SearchSelect from '@components/SearchSelect'
import { maskPlaca } from '@constants/masks'
import selectTiposAgendamento from '@constants/select/tiposAgendamento'
import { setCurrentArmazem } from '@modules/agendamento/reducers/agendamentoReducer'
import { editAgendamento, getMotoristas } from '@modules/agendamento/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import dynamicFind from '@utils/dynamicFind'
import createSelectItems from '@utils/createSelectItems'
import { formSchema } from './config'

const FormEdit: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { clientes, tiposVeiculo, motoristas } = useAppSelector(s => s.agendamento)
    const { agendamentoLista, currentData } = useAppSelector(s => s.agendamento)
    const { painelEditValues } = useAppSelector(s => s.agendamentoEditar)
    const { requestEditAgendamento, requestGetMotoristas } = useAppSelector(s => s.requestAgendamento)

    const SHOW_DATA = !!agendamentoLista && !!clientes && !!tiposVeiculo

    useEffect(() => {
        if(!!agendamentoLista && !!clientes){
            console.log(dynamicFind(clientes, 'nomeFantasia', agendamentoLista.nmCliente, true)?.nomeFantasia)
        }
    }, [agendamentoLista, clientes])

    return(

        <>
            {SHOW_DATA && (
                <Formik
                    initialValues = {{
                        id: agendamentoLista.id,
                        guid: agendamentoLista.guid,
                        tipoAgendamento: agendamentoLista.tipo,
                        cliente: dynamicFind(clientes, 'nomeFantasia', agendamentoLista.nmCliente, true)?.nomeFantasia,
                        peso: agendamentoLista.peso,
                        armazem: agendamentoLista.idArmazem,

                        pesquisaNomeMotorista: '',
                        pesquisaDocMotorista: '',
                        
                        cpfMotorista: '',
                        nomeMotorista: agendamentoLista.nomeMotorista,
                        rgMotorista: agendamentoLista.nrDocMotorista,
                        cnhMotorista: '',
                        placaVeiculo: agendamentoLista.placa,
                        tipoVeiculo: dynamicFind(tiposVeiculo, 'descricao', agendamentoLista.tipoVeiculo, true)?.descricao,
                        placaReboque1: agendamentoLista.placa2,
                        placaReboque2: agendamentoLista.placa3,
                        obs: agendamentoLista.obs,
                    }}
                    validationSchema = {formSchema(userData!)}
                    onSubmit = {v => {
                        editAgendamento(dispatch, v, painelEditValues!, currentData, userData!, dynamicFind(motoristas, 'rg', v.rgMotorista)?.id ?? 0)
                    }}
                >
                    {({ handleSubmit, setFieldValue, handleChange, values, errors }) => (
                        <FormikForm>
                            <Form.Container padding = {false}>
                                <Form.Row grid = "200px 1fr" columns = {2}>
                                    <Select
                                        type = "outline"
                                        label = "Tipo de agendamento"
                                        value = {values.tipoAgendamento}
                                        error = {!!errors.tipoAgendamento}
                                        field = "tipoAgendamento"
                                        setFieldValue = {setFieldValue}
                                        items = {selectTiposAgendamento}
                                    />
                                    <SearchSelect
                                        type = "outline"
                                        label = "Cliente"
                                        value = {values.cliente}
                                        error = {!!errors.cliente}
                                        field = "cliente"
                                        setFieldValue = {setFieldValue}
                                        items = {createSelectItems(clientes, 'nomeFantasia', 'nomeFantasia')}
                                    />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "Peso (KG)"
                                        error = {!!errors.peso}
                                        inputID = "peso"
                                        inputName = "peso"
                                        inputType = "number"
                                        disabled = {![10, 13].includes(userData!.grupoAcesso.id)}
                                    />
                                    <Select
                                        type = "outline"
                                        label = "Armazém"
                                        value = {values.armazem}
                                        error = {!!errors.armazem}
                                        field = "armazem"
                                        setFieldValue = {setFieldValue}
                                        setValue = {v => dispatch(setCurrentArmazem(v))}
                                        items = {createSelectItems(armazens, 'descricao', 'id')}
                                    />
                                </Form.Row>
                                <Form.Row grid = "repeat(2, 1fr) auto" columns = {3}>
                                    <Form.Group
                                        label = "Pesquisar por nome"
                                        inputID = "pesquisaNomeMotorista"
                                        inputName = "pesquisaNomeMotorista"
                                        onChange = {() => getMotoristas(dispatch, { nome: values.pesquisaNomeMotorista, documento: ''}, userData!, setFieldValue)}
                                    />
                                    <Form.Group
                                        label = "Pesquisar por CPF"
                                        inputID = "pesquisaDocMotorista"
                                        inputName = "pesquisaDocMotorista"
                                        inputType = "number"
                                        onChange = {() => getMotoristas(dispatch, { nome: '', documento: values.pesquisaDocMotorista }, userData!, setFieldValue)}
                                    />
                                    <Button
                                        icon = {mdiClose}
                                        loading = {requestGetMotoristas.loading}
                                        status = {requestGetMotoristas.loading ? "disabled" : "error"}
                                        onClick = {() => {
                                            ['pesquisaNomeMotorista', 'pesquisaDocMotorista', 'cpfMotorista', 'nomeMotorista', 'rgMotorista', 'cnhMotorista']
                                            .forEach(key => setFieldValue(key, ''))
                                        }}
                                    />
                                </Form.Row>
                                <Form.Row columns = {4}>
                                    <Form.Group
                                        label = "CPF motorista"
                                        error = {!!errors.cpfMotorista}
                                        inputID = "cpfMotorista"
                                        inputName = "cpfMotorista"
                                    />
                                    <Form.Group
                                        label = "Nome motorista"
                                        error = {!!errors.nomeMotorista}
                                        inputID = "nomeMotorista"
                                        inputName = "nomeMotorista"
                                    />
                                    <Form.Group
                                        label = "RG motorista"
                                        error = {!!errors.rgMotorista}
                                        inputID = "rgMotorista"
                                        inputName = "rgMotorista"
                                        inputType = "number"
                                        
                                    />
                                    <Form.Group
                                        label = "CNH motorista"
                                        error = {!!errors.cnhMotorista}
                                        inputID = "cnhMotorista"
                                        inputName = "cnhMotorista"
                                    />
                                </Form.Row>
                                <Form.Row columns = {4}>
                                    <Select
                                        type = "outline"
                                        label = "Tipo do veículo"
                                        value = {values.tipoVeiculo}
                                        error = {!!errors.tipoVeiculo}
                                        field = "tipoVeiculo"
                                        setFieldValue = {setFieldValue}
                                        items = {createSelectItems(tiposVeiculo, 'descricao', 'descricao')}
                                    />
                                    <Form.Group
                                        label = "Placa"
                                        error = {!!errors.placaVeiculo}
                                        inputID = "placaVeiculo"
                                        inputName = "placaVeiculo"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Placa reboque 1"
                                        inputID = "placaReboque1"
                                        inputName = "placaReboque1"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                    <Form.Group
                                        label = "Placa reboque 2"
                                        inputID = "placaReboque2"
                                        inputName = "placaReboque2"
                                        mask = {maskPlaca}
                                        handleChange = {handleChange}
                                    />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        label = "Observações"
                                        inputID = "obs"
                                        inputName = "obs"
                                        inputType = "textarea"
                                        inputRows = {3}
                                    />
                                </Form.Row>
                                <ButtonGroup>
                                    <Button
                                        label = "Editar agendamento"
                                        status = "success"
                                        loading = {requestEditAgendamento.loading}
                                        onClick = {handleSubmit}
                                    />
                                </ButtonGroup>
                            </Form.Container>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>

    )

}

export default FormEdit