import * as yup from 'yup'
import { UserData } from '@modules/auth/interfaces/UserData'

const defaultSchema = {
    tipoAgendamento: yup.string().required('Campo obrigatório'),
    cliente: yup.string().required('Campo obrigatório'),
    armazem: yup.string().required('Campo obrigatório'),
    cpfMotorista: yup.string().required('Campo obrigatório'),
    nomeMotorista: yup.string().required('Campo obrigatório'),
    rgMotorista: yup.string().required('Campo obrigatório'),
    cnhMotorista: yup.string().required('Campo obrigatório'),
    placaVeiculo: yup.string().required('Campo obrigatório'),
    tipoVeiculo: yup.string().required('Campo obrigatório'),
}

const pesoSchema = {
    peso: yup.string().required('Campo obrigatório')
}

export const formSchema = (user: UserData) => {
    return yup.object().shape({
        ...defaultSchema,
        ...([10, 13].includes(user.grupoAcesso.id)) ? pesoSchema : {}
    })
}