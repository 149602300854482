import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiClock } from '@mdi/js'
import { useFloating, offset, autoPlacement } from "@floating-ui/react"
import { getAgendamento } from '@modules/agendamento/controllers'
import { setAgendamento, setCurrentAgendamento } from '@modules/agendamento/reducers/agendamentoReducer'
import { setOpenModalAgendamento } from '../../reducers/agendamentoConsultaReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { Agendamento } from '@interfaces/Agendamento'
import createClassName from '@utils/createClassName'
import './styles.scss'
import AgendamentoPopup from '../AgendamentoPopup'

const PainelBox: React.FC <Agendamento> = agendamento => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const [openPopup, setOpenPopup] = useState(false)

    const { x, y, refs, strategy } = useFloating({
        placement: "bottom",
        middleware: [
            offset(8),
            autoPlacement(),
            // arrow({element: })
        ],
    })

    const boxClasses = createClassName(
        'painelBox',
        [`label-${agendamento.tipo}`, `status-${agendamento.idStatus}`]
    )

    const handleClick = () => {
        dispatch(setCurrentAgendamento(agendamento))
        getAgendamento(dispatch, agendamento.id, userData!)
        dispatch(setOpenModalAgendamento(true))
    }

    return(

        <div className = {boxClasses}>
            <div
                ref = {refs.setReference}
                className = "painelBoxTouchable"
                onMouseEnter = {() => {
                    setOpenPopup(true)
                }}
                onMouseLeave = {() => {
                    setOpenPopup(false)
                    dispatch(setAgendamento(null))
                }}
                onClick = {handleClick}
            >
                <span>{`${agendamento.tipo} | ${agendamento.docaCarregada ?? ''}`}</span>
                <Icon path = {mdiClock} size = "20px" />
                <span className = "text">{agendamento.placa}</span>
            </div>

            {openPopup && (
                <AgendamentoPopup
                    refs = {refs.setFloating}
                    strategy = {strategy}
                    idAgendamento = {agendamento.id}
                    x = {x ?? 0}
                    y = {y ?? 0}
                    setOpenPopup = {setOpenPopup}
                />
            )}
        </div>

    )

}

export default PainelBox