export default function dynamicFind<T, K extends keyof T>(
    list: T[] | null,
    key: K,
    value: T[K],
    toLower?: boolean
) {
    if (!list) return undefined
    
    const item = list.find(item => {
        if (toLower) {
            return (item[key] as string).toLowerCase() === (value as string | number).toString().toLowerCase()
        }
        return item[key] === value
    })

    return item
}