import React, { useEffect, useState } from 'react'
import Loader, { LoaderContainer } from '@components/Loader'
import ENV from '@env/index'
import { AgendamentoAtual } from '@interfaces/AgendamentoAtual'
import getNroDocumento from '@modules/auth/scripts/getNroDocumento'
import { setAgendamento } from '@modules/agendamento/reducers/agendamentoReducer'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { errorLog } from '@utils/request/scripts/console'
import { jsonToParams } from '@utils/jsonToParams'
import { ResponsePattern } from "@utils/response/types"
import { AgendamentoPopupProps } from './types'

const AgendamentoPopup: React.FC <AgendamentoPopupProps> = ({
    refs,
    strategy,
    x,
    y,
    idAgendamento,
    setOpenPopup
}) => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)

    const [agendamentoPopUp, setAgendamentoPopUp] = useState<AgendamentoAtual>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        if(!!userData){
            const fetchData = async () => {
                setLoading(true)
                setAgendamentoPopUp(undefined)
    
                try {
                    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/Agendamento`
                    const params = `?id=${idAgendamento}&${jsonToParams(getNroDocumento(userData))}`
                    const url = `${endpoint}${params}`

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        signal,
                    })

                    if(!response.ok) throw new Error('Erro ao buscar agendamento')

                    const data: ResponsePattern<AgendamentoAtual[]> = await response.json()

                    if(data.flagErro) throw new Error(data.listaMensagens[0])

                    setAgendamentoPopUp(data.listaResultados[0])
                } catch (error: any) {
                    errorLog('GET', 'AgendamentoPopup', error)
                } finally {
                    setLoading(false)
                }
            }

            fetchData()
        }

        return () => {
            abortController.abort()
        }
    }, [userData, idAgendamento])

    return(

        <div
            ref = {refs}
            className = "popupContainer"
            style = {{
                position: strategy,
                top: y,
                left: x,
            }}
            onMouseEnter = {() => setOpenPopup(true)}
            onMouseLeave = {() => {
                setOpenPopup(false)
                dispatch(setAgendamento(null))
            }}
        >
            {loading && (
                <LoaderContainer>
                    <Loader size = {24} />
                </LoaderContainer>
            )}
            {(!!agendamentoPopUp && !loading) && (
                <>
                    <div className = "dataTextContainer">
                        <span className = "title">ID</span>
                        <span className = "text">{agendamentoPopUp.id}</span>
                    </div>

                    <div className = "dataTextContainer">
                        <span className = "title">Tipo</span>
                        <span className = "text">{agendamentoPopUp.tipo === 'C' ? 'Carga' : 'Descarga'}</span>
                    </div>

                    <div className = "dataTextContainer">
                        <span className = "title">Cliente</span>
                        <span className = "text">{agendamentoPopUp.nmCliente}</span>
                    </div>

                    <div className = "dataTextContainer">
                        <span className = "title">Tipo</span>
                        <span className = "text">{agendamentoPopUp.tipoVeiculo}</span>
                    </div>

                    <div className = "dataTextContainer">
                        <span className = "title">Motorista</span>
                        <span className = "text">{agendamentoPopUp.nomeMotorista}</span>
                    </div>

                    <div className = "dataTextContainer">
                        <span className = "title">Placa</span>
                        <span className = "text">{agendamentoPopUp.placa}</span>
                    </div>
                </>
            )}
        </div>

    )

}

export default AgendamentoPopup