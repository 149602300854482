import { mdiCalendarMonthOutline } from "@mdi/js"
import { RouteType } from "@routes/interfaces"

const agendamentoRoutes: RouteType[] = [
    {
        key: 'agendamento',
        path: 'agendamento',
        title: 'Agendamento',
        icon: mdiCalendarMonthOutline,
        userVerification: true,
        subRoutes: [
            {key: 'agendamentoLista', path: '', title: 'Agendamentos', userVerification: true},
            {key: 'agendamentoLista', path: ':guid', title: 'Agendamentos', userVerification: true},
            {key: 'agendamentoCadastro', path: 'cadastro', title: 'Novo Agendamento', userVerification: true},
            {key: 'agendamentoConsulta', path: 'consulta', title: 'Consulta Liberados', userVerification: true},
            {key: 'agendamentoConsultaBloqueados', path: 'consultaBloqueados', title: 'Consulta Bloqueados', userVerification: true},
            {key: 'agendamentoCadastroFiscal', path: 'cadastroFiscal/:guid', title: 'Cadastro de Notas Fiscais', showMenu: false, userVerification: true},
            {key: 'agendamentoCadastroHorario', path: 'cadastroHorario/:guid/:guidArmazem/:idArmazem', title: 'Cadastro de Horário', showMenu: false, userVerification: true},
            {key: 'agendamentoEditar', path: 'editar/:guid/:idAgendamento', title: 'Editar Agendamento', showMenu: false, userVerification: true},
            {key: 'agendamentoImpressao', path: 'impressao/:guid/:idAgendamento', title: 'Imprimir Agendamento', showMenu: false, userVerification: true},
        ]
    }
]

export default agendamentoRoutes